<template>
    <div>
        <div class="detail-title">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>教研中心</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/stock' }">图书库存</el-breadcrumb-item>
                <el-breadcrumb-item>图书详情</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <div class="course-detail-title">
            <div style="padding: 10px;display:flex">
                <div class="fl-l" style="width: 150px">
                    <img height="80px" width="150px" :src="imageUrl + detail.pic" />
                </div>
                <span style="display: block;width: 20px;"></span>
                <div class="fl-l margin-l-sm">
                    <span style="display: block;height: 20px;"></span>
                    <p>
                        <span>图书名字：<b>{{ detail.name }}</b></span>
                    </p>
                    <span style="display: block;height: 20px;"></span>
                    <p>
                        <span>图书库存：<b>{{ detail.stock_nums }}</b></span>
                    </p>
                </div>

                <div class="clear"></div>
            </div>
        </div>

        <div class="margin-sm">
            <div class="table-content">

                <el-table :header-cell-style="{ padding: 0 }" ref="singleTable" :data="tableData" highlight-current-row
                    v-loading="loading" style="width: 100%;background: #fff;">
                    <el-table-column label="状态">
                        <template slot-scope="scope">
                            <el-tag :type="scope.row.type == 1 ? 'success' : 'danger'" size="mini">{{ scope.row.type
                                    ==
                                    1 ? '入库' : '出库'
                            }}</el-tag>
                        </template>
                    </el-table-column>

                    <el-table-column property="nums" label="操作的数量">
                    </el-table-column>

                    <el-table-column property="created_at" label="出入库的时间">
                    </el-table-column>

                    <el-table-column property="operator" label="操作人">
                    </el-table-column>

                    <el-table-column property="remarks" label="备注">
                    </el-table-column>

                    <el-table-column property="created_at" label="创建时间">
                    </el-table-column>

                    <el-table-column property="updated_at" label="更新时间">
                    </el-table-column>

                    <el-table-column property="address" label="操作">
                        <template slot-scope="scope">
                            <span>
                                <el-link slot="reference" type="primary" @click="editDetail(scope.row)">编辑</el-link>
                            </span> -
                            <span>
                                <el-popconfirm icon="el-icon-info" icon-color="red" title="删除后将不可恢复，确定删除吗？"
                                    @confirm="deleted(scope.row)">
                                    <el-link slot="reference" type="primary">删除</el-link>
                                </el-popconfirm>
                            </span>
                        </template>
                    </el-table-column>
                </el-table>

            </div>

            <div class="table-batch">
                <span class="fl-r">
                    <el-pagination small background :current-page="page" :page-sizes="[10, 20]" :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper" @size-change="setPageSize"
                        @current-change="setPage" :total="total">
                    </el-pagination>
                </span>
                <div style="clear: both"></div>
            </div>
        </div>


        <el-dialog title="编辑" append-to-body :visible.sync="dialogVisible" width="40%">
            <el-form :model="form" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <el-form-item label="出入库" label-position="left" prop="type">
                    <el-radio-group disabled fill="#409EFF"  v-model="form.type">
                        <el-radio-button label="出库"></el-radio-button>
                        <el-radio-button label="入库"></el-radio-button>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="进出库数量" label-position="left" prop="nums">
                    <el-input style="width: 200px" v-model.number="form.nums"></el-input>
                </el-form-item>
                <el-form-item label="进出库时间" label-position="left" prop="stockAt">
                    <el-date-picker v-model="form.stockAt" type="datetime" placeholder="选择日期时间" default-time="12:00:00">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="备注" label-position="left" prop="remarks">
                    <el-input style="width: 200px" v-model="form.remarks"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
                    <el-button @click="dialogVisible = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
    name: 'detail',
    data() {
        return {
            //头部图文数据
            detail: {},
            //表格的数据
            tableData: [],
            //分页
            page: 1,
            //每页条数
            pageSize: 10,
            //总条数
            total: 0,
            //列表的数据加载动画
            loading: false,
            //图片前缀
            imageUrl: config.imageUrl,
            //数据编辑
            dialogVisible: false,
            //出入口图书
            form: {},
            //对话框表单验证
            rules: {
                nums: [
                    { required: true, message: '请输入库存数量', trigger: 'blur' },
                    { type: 'number', message: '库存必须为数字值' }
                ],
                type: [
                    { required: true, message: '请选择图书状态', trigger: 'change' },
                ],
                stockAt: [
                    { required: true, message: '请选择日期', trigger: 'change' }
                ],
                remarks: [
                    { required: true, message: '请输入活动名称', trigger: 'blur' },
                    { min: 2, message: '长度在 2 个字符以上', trigger: 'blur' }
                ]
            },
        }
    },
    methods: {
        ...mapActions('store', ['getBookStockRecordList', 'getBookStockGet', 'changeBookStock', 'delBookStockRecord']),
        //切换分页的方法
        setPageSize(pageSize) {
            this.pageSize = pageSize
            this.initData()
        },
        //分页数据的方法
        setPage(page) {
            this.page = page
            this.initData()
        },
        //编辑
        editDetail(row) {
            this.form = JSON.parse(JSON.stringify(row))
            this.form.stockAt = this.form.stock_at
            this.form.type = this.form.type == 1 ? '入库' : '出库'
            this.dialogVisible = true
            this.form.id = row.id
        },
        //出入库数据保存
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let form = JSON.parse(JSON.stringify(this.form))
                    form.bookStockId = this.detail.id
                    form.type = form.type == "出库" ? 2 : 1
                    if (form.type == 2 && this.detail.stock_nums < form.nums) {
                        this.$message.error('出库的数量不能大于原始库存')
                        return
                    }
                    this.changeBookStock(form).then(res => {

                        if (res.res_info == "ok") {
                            this.dialogVisible = false
                            this.initData()
                            this.$message.success('编辑成功')
                        }
                    })
                } else {
                    return false;
                }
            })
        },
        //删除
        deleted(row) {
            this.delBookStockRecord(row.id).then(res => {
                if (res.res_info == "ok") {
                    this.initData()
                    this.$message.success('删除成功')
                }
            })
        },
        //初始化数据
        initData() {
            this.loading = true
            let data = {
                id: this.$route.params.id,
                page: this.page,
                pageSize: this.pageSize
            }
            this.getBookStockGet(this.$route.params.id).then(res => {
                this.detail = res.data
            })
            this.getBookStockRecordList(data).then(res => {
                this.tableData = res.data.list
                this.total = res.data.total
                this.loading = false
            })
        }
    },
    mounted() {
        this.initData()
    }
}
</script>

<style>
.course-detail-title {
    margin: 10px;
    background: #fafafa;
    overflow: hidden;
}

.title {
    font-size: 18px;
    margin-top: 5px;
}

.price {
    margin-top: 30px;
    font-size: 16px;
}
</style>
